import phoneCustomer from "../../public/assets/images/phone-customer.png";
import phoneRider from "../../public/assets/images/phone-rider.png";
import phoneVendor from "../../public/assets/images/phone-vendor.png";

import img1 from "../../public/assets/images/img1.jpg";
import img2 from "../../public/assets/images/img2.png";
import img3 from "../../public/assets/images/img3.png";

import {
  BusinessSvg,
  DeliverySvg,
  DownloadSvg,
  ExploreSvg,
  FastSvg,
  FlexibleSvg,
  IncreaseSvg,
  LessSvg,
  SendPackageSvg,
  TrackSvg,
} from "../../public/assets/svg/AppRoleSvg";

export const AppRoleContentsDate = {
  customer: {
    model: "customer",
    bannerImg: img1,
    bannerTitle: "Get the App",
    appLink: {
      apple: "https://apps.apple.com/us/app/pointxy-food-products/id6739723728",
      google:
        "https://play.google.com/store/apps/details?id=com.dayoaturu.pointcustomer",
    },
    phoneImg: phoneCustomer,
    contents: [
      {
        title: "Download the App",
        subTitle: "Get the PointXY App on the Google play store or IOS store.",
        icon: DownloadSvg,
      },
      {
        title: "Send Package",
        subTitle: "Send items easily within our coverage, hassle-free.",
        icon: SendPackageSvg,
      },
      {
        title: "Explore Vendors",
        subTitle: "We’ll suggest stores and restaurants you can order from.",
        icon: ExploreSvg,
      },
      {
        title: "Track Orders",
        subTitle: "Place your order, sit back, relax and track its progress. ",
        icon: TrackSvg,
      },
    ],
  },
  vendor: {
    model: "vendor",
    bannerImg: img3,
    bannerTitle: "Start Selling",
    appLink: {
      apple: "https://apps.apple.com/us/app/xy-vendor/id6739723533",
      google:
        "https://play.google.com/store/apps/details?id=com.dayoaturu.pointvendor2",
    },
    phoneImg: phoneVendor,
    contents: [
      {
        title: "Download the App",
        subTitle:
          "Get the PointXY Vendor App on the Google play store or IOS store.",
        icon: DownloadSvg,
      },
      {
        title: "Increase Visibility ",
        subTitle:
          "Enhance visibility to a broader customer audience and increase sales.",
        icon: IncreaseSvg,
      },
      {
        title: "Less Worries",
        subTitle: "Let’s handle logistics for your products to your Customers.",
        icon: LessSvg,
      },
      {
        title: "Business Growth",
        subTitle:
          "Gain business insights, receive fast payouts and loads more...",
        icon: BusinessSvg,
      },
    ],
  },
  rider: {
    model: "rider",
    bannerImg: img2,
    bannerTitle: "Become a Rider",
    appLink: {
      apple: "https://apps.apple.com/us/app/pointxy-rider/id6739723556",
      google:
        "https://play.google.com/store/apps/details?id=com.dayoaturu.pointrider",
    },
    phoneImg: phoneRider,
    contents: [
      {
        title: "Download the App",
        subTitle:
          "Get the PointXY Rider App on the Google play store or IOS store.",
        icon: DownloadSvg,
      },
      {
        title: "Be Flexible",
        subTitle: "Join us as a PointXY Rider and earn on your terms.",
        icon: FlexibleSvg,
      },
      {
        title: "Deliver Goodness",
        subTitle:
          "Take the lead in delivering goodness and representing our values.",
        icon: DeliverySvg,
      },
      {
        title: "Fast Payments",
        subTitle: "Receive prompt payouts to optimise your cash flow.",
        icon: FastSvg,
      },
    ],
  },
};

export const navData = ["Home", "About us", "FAQs"];

export const oneStopAds = [
  "From Order to Doorstep",
  "Your Needs, Our Speed!",
  "Delight Delivered",
  "Every Delivery with Convenience",
  "Order, Track, Enjoy",
  "From Order to Doorstep",
  "Your Needs, Our Speed!",
  "Delight Delivered",
  "Every Delivery with Convenience",
  "Order, Track, Enjoy",
];

export const footerData = {
  company: ["Customer", "Vendor", "Rider", "About us", "FAQs"],
  legal: ["Terms of Use", "Privacy Policy", "Cookie Policy", "Disclaimer"],
  contact: ["hello@pointxy.app"],
};

export const faqData = [
  {
    q: "How does pointXY work?",
    a: "You can order through the app, available on iOS and Android. Sign up takes less than a minute. Explore our options thereafter, select the restaurant or store you want to order from, Select your preferences and head to checkout.",
  },
  {
    q: "Does PointXY accept cash payment?",
    a: "We accept payments through a 3rd party payment provider Paystack. We also accept in-app wallet payments which you can fund via paystack.",
  },
  {
    q: "Can I order from multiple restaurants in one order?",
    a: "You can add items from different vendors to cart but would need to check out separately from each vendor.",
  },
  {
    q: "What’s the delivery timeframe?",
    a: "There is no fixed delivery timeframe, but you’ll be able to view the average time each restaurant or store takes to get your order ready before you place one and can track when your order is on its way.",
  },
  {
    q: "Can I track my order in real-time?",
    a: "Yes, once your order is confirmed, you can track its status in real-time through the PointXY app.",
  },
  {
    q: "What happens if the delivery person cannot find my address?",
    a: "If the delivery person has trouble locating your address, they will contact you through the app or a phone call. Do ensure your contact details on the PointXY app are up to date.",
  },
  {
    q: "What if my order is late?",
    a: "If your order is significantly delayed, you can reach our customer support for assistance. ",
  },
  {
    q: "How do I send an item using PointXY?",
    a: "To send an item, go to the “Send Package” section, enter the origin and destination details, provide the sender and receivers name, item descriptions, and follow the prompts to complete the request.",
  },
  {
    q: "How do I package items for sending?",
    a: "Ensure that items are securely packaged to prevent damage during transit. Use appropriate materials like bubble wrap and sturdy boxes for fragile items.",
  },
  {
    q: "What types of items can I send?",
    a: "You can send most small to medium-sized items that can be carried by one person. Prohibited items include illegal goods, hazardous materials, and in some cases, perishables.",
  },
  {
    q: "Are there any hidden fees?",
    a: "All fees are clearly displayed before you complete your order or sending request. These may include delivery fees and service fees.",
  },
  {
    q: "What is the service fee, and why is it charged?",
    a: "Our service fee is a small percentage added to your order total to help cover operational costs and app maintenance. This fee is clearly displayed before you finalise your order. To ensure affordability and fairness, the service fee is capped at 100 Naira, so you won’t pay more than this amount regardless of your order size. This cap helps us balance the need to maintain high-quality service while considering cost concerns. ",
  },
  {
    q: "What is the service fee, and why is it charged?",
    a: "Our service fee is a small percentage added to your order total to help cover operational costs and app maintenance. This fee is clearly displayed before you finalise your order. To ensure affordability and fairness, the service fee is capped at 100 Naira, so you won’t pay more than this amount regardless of your order size. This cap helps us balance the need to maintain high-quality service while considering cost concerns. ",
  },
  {
    q: "How do I contact customer support?",
    a: "You can reach our customer support team through Profile → Customer Support → Send us a Message on the pointXY app.",
  },
  {
    q: "What should I do if there's an issue with my order?",
    a: "If there's a problem with your order, use the 'Report' feature in the app or contact customer support immediately for assistance.",
  },
  {
    q: "Can I cancel my order?",
    a: "Please contact our customer support team through the PointXY app regarding order cancellations.",
  },
  {
    q: "What is your refund policy?",
    a: "Refunds are handled on a case-by-case basis. If you're unsatisfied with your order, please report the issue in the app within 6 hours of delivery for review. Please note this doesn’t guarantee a refund will be issued. ",
  },
  {
    q: "How can I provide feedback about my experience?",
    a: "You can provide feedback through the pointXY app Profile → Customer Support → Send us a Message We value your input to improve our services.",
  },
  {
    q: "How do I update my account information?",
    a: "To update your account information, go to your profile in the app, select 'Edit Profile,' and make the necessary changes.",
  },
  {
    q: "Can I delete my PointXY account?",
    a: "Yes, you can request an account deletion by contacting us at hello@pointxy.app. Please note that this action is permanent and cannot be undone.",
  },
];

export default { navData, footerData, oneStopAds, faqData };
